@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Karla');

html {
  font-size: 100% !important;
  -webkit-tap-highlight-color: inherit !important;
  overflow: auto !important;
  height: auto !important;
}

input[type=text]:focus {
  outline: none !important;
  outline-offset: 0 !important;
}
*:focus {
  outline: none !important;
}

.App {
  font-family: "Poppins";
}
